
import {GET_USER_INFO} from "../constants";


export const setUserInfo = (data) => {
    return (dispatch) => {
        return dispatch({
                type: GET_USER_INFO,
                data: data
        });
    }
};
