import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import API from "../../api";
const api = new API();

class CommonStaticSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags : [],
            active : null
        };
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    componentDidMount() {
        api.getAllTags().then(tags => {
            this.setState({tags: tags.data});
        });
        if (this.props.cookies.get("toggle") && this.props.cookies.get("toggle") !== undefined && this.props.cookies.get("toggle") === "toggled") {
            this.props.cookies.set("toggle", "");
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cookies.get("toggle") && this.props.cookies.get("toggle") !== undefined && this.props.cookies.get("toggle") === "toggled") {
            this.props.cookies.set("toggle", "");
        }
    }

    render() {

        const path_string = this.props.location.pathname.toString() ;

        return (
            <>
                <React.Fragment>
                    <div className={this.props?.name ? "col-md-3 sidebar pt-0 mb-0 mt-4" : "col-md-3 sidebar pt-0" } >
                        <div className="main-section px-3 bg-white text-left">
                            <h2 className="mb-3">Meet the Farmer's</h2>
                            <ul className="menu-static">
                                <li>
                                    <a className={path_string === "/about" ? "active" : ""}>
                                        <NavLink exact to="/about">About us</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/wholesale" ? "active" : ""}>
                                        <NavLink exact to="/wholesale">Wholesale</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/restaurants-wholesale" ? "active" : ""}>
                                        <NavLink exact to="/restaurants-wholesale">Restaurants - Wholesale</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/food-truck-wholesale" ? "active" : ""}>
                                        <NavLink exact to="/food-truck-wholesale">Food Trucks - Wholesale</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/catering" ? "active" : ""}>
                                        <NavLink exact to="/catering">Catering</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/about/our-meat" ? "active" : ""} >
                                        <NavLink exact to="/about/our-meat">Our meat</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/on-sale-now" ? "active" : ""} >
                                        <NavLink exact to="/on-sale-now">Weekly Sale</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/specialty-services" ? "active" : ""} >
                                        <NavLink exact to="/about/specialty-services"> Specialty services</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/about/employment" ? "active" : ""}>
                                        <NavLink exact to="/about/employment">Employment</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/our-selection/specials-monthly-salepaper" ? "active" : ""} >
                                        <NavLink exact to="/our-selection/specials-monthly-salepaper">Specials</NavLink>
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <a className={path_string === "/on-sale-now" ? "active" : ""}>*/}
                                {/*        <NavLink exact to="/on-sale-now">On sale now</NavLink>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a className={path_string === "/best-seller-products" ? "active" : ""}>*/}
                                {/*        <NavLink exact to="/best-seller-products">Best Seller Products</NavLink>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href="/our-selection/specials-monthly-salepaper">*/}
                                {/*        Welcome*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href="/testimonial">*/}
                                {/*        Feedback*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                <li>
                                    <a className={this.props.location.pathname.startsWith("/blog") ? "active" : ""}>
                                        <NavLink exact to="/blogs">Articles & Recipes</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/frequent-asked-question" ? "active" : ""}>
                                        <NavLink exact to="/frequent-asked-question">FAQs</NavLink>
                                    </a>
                                </li>
                                <li>
                                    <a className={path_string === "/promotion" ? "active" : ""}>
                                        <NavLink exact to="/promotion">Promotions</NavLink>
                                    </a>
                                    {/*<ul>*/}
                                    {/*    <li>*/}
                                    {/*        <a className={path_string === "/text-cullen" ? "active" : ""}>*/}
                                    {/*            <NavLink exact to="/text-cullen"> - Cullen </NavLink>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <a className={path_string === "/text-mesa" ? "active" : ""}>*/}
                                    {/*            <NavLink exact to="/text-mesa"> - Mesa </NavLink>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                </li>
                            </ul>
                        </div>

                        {this.props.location.pathname.startsWith("/blog") &&

                            <div className="main-section px-3 bg-white border-0 text-left">
                                <h2>Tags</h2>
                                <ul className="tags-article">
                                    {this.state.tags.length > 0 && this.state.tags.map((tag, index) => {
                                        return (
                                            <li key={index} className="d-inline-block" onClick={() => {
                                                this.setState({active: tag.name}, () => {
                                                    this.props.history.push({
                                                        pathname: '/blog-tag-archives',
                                                        state: {id: tag.id, name: tag.name}
                                                    }, 'blog-tag-archives')
                                                });
                                            }}>
                                                <a className={(this.state.active ? this.state.active : this.props?.tag) === tag.name ? "text-capitalize d-inline-block rounded-sm font-10 active" : "text-capitalize d-inline-block rounded-sm font-10"}>
                                                    {tag.name}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        }

                    </div>
                </React.Fragment>
            </>
        );
    }
}

export default withRouter(withCookies(CommonStaticSidebar));



