import React from 'react';
import {Cookies, withCookies} from "react-cookie";
import {withSnackbar} from "notistack";
import {instanceOf} from "prop-types";
import API from "../../api";
import CateCard from "./cateCard";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sidebar from "./sidebar";
import {images} from "../../constant";
import cookie from "cookie";
import {updateList} from "../../actions/list";
const api = new API();

class CateringGrid extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    static async getInitialProps(ctx) {
        const { req , match } = ctx;
        let selectedStore = null;
        if (req && req.headers && req.headers.cookie) {
            // On the server-side, you can access cookies using headers.cookie
            const cookies = cookie.parse(req.headers.cookie);
            selectedStore = cookies?.selected_store || 2; //Default mesa;
        } else if (typeof window !== 'undefined') {
            // On the client-side, you can access cookies using document.cookie
            const cookies = cookie.parse(document.cookie);
            selectedStore = cookies?.selected_store || 2; //Default mesa;
        }
        const catering_products = await api.getFilterCateringProduct(selectedStore);
        return {
            all_products: catering_products.data.response,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            all_products: [],
            toggle_menu_class:"sidebar bg-white",
            restaurant_id: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
        };
    }

    componentDidMount() {
        if (!this.props.list || Object.keys(this.props.list).length === 0) {
            this.props.updateUserList({
                token: this.props.cookies.get('token') || "default",
                list_unique_id: this.props.cookies.get('list_unique_id') || "default",
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.restaurant_id){
            this.setState({restaurant_id: this.props.cookies.get('selected_store')},()=>{
                this.getCateringProducts()
            });
        }
    }

    getCateringProducts() {
        api.getFilterCateringProduct(this.state.restaurant_id).then(products => {
            this.setState({all_products: products.data.response})
        });
    }

    render() {
        const products = this.state.all_products.length > 0 ? this.state.all_products : this.props.all_products;
        return (
            <React.Fragment>
                <section className="">
                    <div className="container-fluid mb-fix">
                        <div className="row">

                            <Sidebar categories_slug={"catering"} showFilter={this.state.toggle_menu_class}/>

                            <div className="col-md-9 column catering-main">
                                <div className="title mt-3 mb-4 d-flex align-items-center justify-content-between">
                                    <h2 className="mb-0">Catering Items</h2>
                                </div>
                                <div className="row display-details">
                                    {(products && !!products.length) ?
                                        products.map((val, index) => {
                                            return <CateCard key={index} val={val}/>
                                        })
                                        :
                                        <div className="py-3 blank-banner mt-5">
                                            <img src={images.order_food}/>
                                            <h2 className="mt-3 mb-1">Item not found</h2>
                                                <p className="mt-1 mb-3">
                                                    <span>We couldn’t find any items. Please try a different product category from our menu.</span>
                                                </p>
                                            <button onClick={() => {this.props.history.push({pathname: '/'})}} className="mx-auto btn btn-m btn-info w-auto px-3">
                                                View all items
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        defaultStore: state.StoreReducer.selectedStore,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserList: (val) => dispatch(updateList({type: 'USER_LIST', data: val})),
    }
};

export default withRouter(withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CateringGrid))));

