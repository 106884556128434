import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {NavLink} from "react-router-dom";
import API from "../../../api";
import CateCard from "../../Common/cateCard";
import {connect} from "react-redux";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import ScrollContainer from "react-indiana-drag-scroll";
import FoodCard from "../../Common/foodCard";
const api = new API();
class CateRelated extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            product_id : null,
            all_products :[],
            default_store : this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
        }
    }

    // componentDidMount() {
    //     if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store){
    //         this.setState({default_store: this.props.cookies.get('selected_store') },()=>{
    //             this.getCateringRelatedProducts();
    //         });
    //     }
    //     if (this.props.product_id !== this.state.product_id){
    //         this.getCateringRelatedProducts();
    //     }
    // }
    //
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //
    //     if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store){
    //         this.setState({default_store: this.props.cookies.get('selected_store') },()=>{
    //             this.getCateringRelatedProducts();
    //         });
    //     }
    //
    //     if (prevProps.product_id !== this.props.product_id || this.state.all_products.length === 0) {
    //        this.setState({product_id : this.props.product_id},()=>{
    //            this.getCateringRelatedProducts()
    //        })
    //     }
    // }
    //
    // getCateringRelatedProducts(){
    //     api.getFilterCateringProduct({},this.state.default_store).then(products =>{
    //         this.setState({all_products: products.data.response ? products.data.response : []})
    //     });
    // }

    render() {

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5,
                slidesToSlide: 1, // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1.5,
                slidesToSlide: 1 // optional, default to 1.
            }
        };

        const ButtonGroup = ({ next, previous, ...rest }) => {
            const {
                carouselState: { currentSlide, totalItems, slidesToShow }
            } = rest;

            return (
                <div className="carousel-button-group">
                    <button aria-label="Go to previous slide"
                            className={currentSlide === 0 ? "disable" : "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"}
                            onClick={() => previous()}></button>
                    <button aria-label="Go to next slide"
                            className={currentSlide === totalItems - slidesToShow ? "disable" : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"}
                            onClick={() => next()}></button>
                </div>
            );
        };

        return (

            <>
            <React.Fragment>
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 p-0">
                    <div className="title">
                        <h2>Related Products</h2>
                        <NavLink to="/"  className="more-info">
                            See more{' '}
                        </NavLink>
                    </div>
                </div>
                { !!this.props.products && this.props.products.length > 0 ? (
                    <div className="col-12 p-0">
                        <div className="col-12 p-0 cus-slider">
                            <ScrollContainer
                                style={{zIndex: 0}}
                                className="scroll-container"
                                hideScrollbars={false}
                            >
                                {this.state.all_products.length > 4 && this.state.scrollX !== 0 ?
                                    <button
                                        className="nav-btn prev"
                                        onClick={() => this.slide(-500)}
                                    >
                                        <i className="fa fa-angle-left"></i>
                                    </button>
                                    : ""}


                                <ul className="cus-slider" ref={this.scrl} onScroll={this.scrollCheck}>
                                    {this.props.products.map((val, index) => {
                                        return <CateCard key={index} val={val} />;
                                    })}
                                </ul>

                                {this.state.all_products.length > 4 && !this.state.scrolEnd ?
                                    <button
                                        className="nav-btn next"
                                        onClick={() => this.slide(+500)}
                                    >
                                        <i className="fa fa-angle-right"></i>
                                    </button>
                                    : ""}

                            </ScrollContainer>
                        </div>
                    </div>
                    // <div>
                    //     <div className="col-12 col-md-12 col-lg-12 mb-m d-none d-md-block p-0">
                    //         <Carousel
                    //             customButtonGroup={<ButtonGroup
                    //                 next={this.props.next}
                    //                 previous={this.props.previous}
                    //                 rest={this.props.rest}
                    //             />}
                    //             renderButtonGroupOutside={true}
                    //             arrows={false}
                    //             swipeable={false}
                    //             responsive={responsive}
                    //             ssr={true} // means to render carousel on server-side.
                    //             infinite={true}
                    //             transitionDuration={500}
                    //         >
                    //             {this.props.products.map((val, index) => {
                    //                 if (val.item_id !== this.props.product_id) {
                    //                     return <CateCard key={index} val={val}/>;
                    //                 }
                    //             })}
                    //         </Carousel>
                    //     </div>

                    // </div>

                ) : null }

            </React.Fragment>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        defaultStore: state.StoreReducer.selectedStore,
    };
};

export default withCookies(connect(mapStateToProps, null)(CateRelated));
