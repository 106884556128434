import React from "react";
const api = new API();
import API from "../../api";
import Related from "../Home/children/related";
import {withCookies} from "react-cookie";

class BestSellerGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            all_products: [],
            default_store: this.props.cookies.get('selected_store') ? this.props.cookies.get('selected_store') : 2,
        }
    }

    componentDidMount() {
        this.getSaleProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')}, () => {
                this.getSaleProducts();
            });
        }
    }

    getSaleProducts() {
        var data = {
            keyword: null,
            range: [0, 1000],
            categories_slug: null,
            categories_name: null,
            restaurant_id: this.state.default_store,
            filter: {
                is_prime: false,
                is_bulk: false,
                made_locally: false,
                is_on_sell: false
            },
            lowToHigh: false,
            highToLow: false
        };
        api.getBestSellerProducts(data).then(products => {
            this.setState({all_products: products.data.response.data})
        })
    }

    render() {

        const products = this.state.all_products?.length > 0 ? this.state.all_products : [] ;

        return (
            <>
                <React.Fragment>
                    {products.length > 0 &&
                        <div className="single-related">
                            <Related products={products}/>
                        </div>
                    }
                </React.Fragment>
            </>
        );
    }
}

export default withCookies(BestSellerGrid);



