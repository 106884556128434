import React from 'react';
import {updateCart} from "../../actions/cart";
import {withCookies} from "react-cookie";
import {withSnackbar} from "notistack";
import {connect} from "react-redux";
const api = new API();
import FoodCard from "./foodCard";
import API from "../../api";
import CommonStaticSidebar from "../Static/commonStaticSidebar";
import Promotion from "../Static/Promotion";

class OnSaleProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            all_products: [],
            default_store: this.props.cookies.get('selected_store') ? this.props.cookies.get('selected_store') : 2,
        }
    }

    static async getInitialProps({req, res, match, history, location, ...ctx}) {
        var data = {categories_slug: "weekly-sale",restaurant_id : 2 };
        const products = await api.getFilterProduct(data);
        return {
            all_products: products.data.response.data,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')}, () => {
                this.getSaleProducts();
            });
        }
    }

    getSaleProducts() {
        var data = {categories_slug: "weekly-sale",restaurant_id : this.state.default_store};
        api.getFilterProduct(data).then(products => {
            this.setState({all_products: products.data.response.data})
        })
    }

    render() {

        const products = this.state.all_products.length > 0 ? this.state.all_products : this.props.all_products;

        return (
            <React.Fragment>
                <section className="full-grid">
                    <div className="container-fluid mb-fix">
                        <div className="row">
                            <CommonStaticSidebar name="mt-4" />
                            <div className="col-9 mx-auto">
                                <div className="title mt-4 mb-3 px-0 d-flex align-items-center justify-content-between">
                                    <h2 className="mb-0">Product On Sale</h2>
                                </div>
                                <div className="row display-details">
                                    {!!products.length &&
                                        products.map((val, index) => {
                                            return <FoodCard key={index} val={val}/>
                                        })
                                    }
                                </div>
                                <div className="promo-export">
                                    <Promotion home={true} slider={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: "UPDATE_CART_ITEMS", data: val})),
    };
};

export default withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(OnSaleProduct)));

