import React from "react";
import {connect} from "react-redux";
import {images} from "../../constant";
import API from "../../api";
import {updateCart} from "../../actions/cart";
import {Badge} from "react-bootstrap";
import {
    CapitalizeFirstLetter,
    capitalizeWords,
    convertTimStampToDateTime,
    formatDate,
    formatNumber
} from "../../helper";
import {withRouter} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DeliveryOfflineModal from "../Common/DeliveryOfflineModal";
import {withSnackbar} from "notistack";
import CartDropDown from "../Common/CartDropDown";

const api = new API();
let timerId;

class CartItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quantity_product: null,
            quantity: null,
            quantity_amount: null,
            quantity_variable: null,
            quantity_customization: null,
            checkout: true,
            itemTurkey: null,
            Change: null,
            cartItems: [],
            price: 0,
            total_tax: 0,
            showDeliveryOfflineConflictModal : false,
            mrgPrice: 0,
            price_with_charges: 0,
            discount: 0,
            applied_code: null,
            code_description: null,
            remove_confirm: "table-row slide-items",
            selectedStore: this.props.store,
            selectedPreference: this.props.preference,
            max_preparation_time: [],
            delivery_datetime: "",
            delivery_tip: 0,
            updating: false,
            delivery_overall_weight : 0
        };
        this.selectHandler = this.selectHandler.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
        this.save = this.save.bind(this);
    }

    handleRedirection(path) {
        this.props.history.push(path);
    }

    componentDidMount() {
        if (this.props.cartAddedItems.cart_items != null) {
            this.setState({
                cartItems: this.props.cartAddedItems.cart_items ? this.props.cartAddedItems.cart_items : [],
                price: this.props.cartAddedItems.order_total ? Number(this.props.cartAddedItems.order_total).toFixed(2) : 0,
                mrgPrice: this.props.cartAddedItems?.mrg_price_sub_total ? Number(this.props.cartAddedItems?.mrg_price_sub_total).toFixed(2) : 0,
                total_tax: this.props.cartAddedItems?.total_tax ? Number(this.props.cartAddedItems?.total_tax).toFixed(2) : 0,
                price_with_charges: this.props.cartAddedItems.total_amount ? Number(this.props.cartAddedItems.total_amount).toFixed(2) : 0,
                discount: this.props.cartAddedItems.coupon_discount ? this.props.cartAddedItems.coupon_discount : 0,
                applied_code: this.props.cartAddedItems.coupon_code ? this.props.cartAddedItems.coupon_code : null,
                max_preparation_time: this.props.cartAddedItems.cart_items.map(function (a) {
                    return a.product.preparation_time
                }),
                code_description: this.props.cartAddedItems ? this.props.cartAddedItems.coupon_code_description : null,
                delivery_charge: this.props.cartAddedItems?.delivery_fee ? this.props.cartAddedItems?.delivery_fee : 0,
                delivery_tip: this.props.cartAddedItems?.delivery_tip ? this.props.cartAddedItems?.delivery_tip : 0,
                delivery_datetime: this.props.cartAddedItems?.delivery_datetime ? this.props.cartAddedItems?.delivery_datetime : "",
                delivery_overall_weight: this.props.cartAddedItems.delivery_overall_weight ? this.props.cartAddedItems.delivery_overall_weight : 0,
            });
        }
        if (this.props.store != null) {
            this.setState({selectedStore: this.props.store});
        }
        if (this.props.preference != null) {
            this.setState({selectedPreference: this.props.preference});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cartAddedItems.total_amount !== this.props.cartAddedItems.total_amount || prevProps.cartAddedItems.updated_at !== this.props.cartAddedItems.updated_at || prevProps.cartAddedItems.coupon_discount !== this.props.cartAddedItems.coupon_discount || prevProps.cartAddedItems.coupon_code_description !== this.props.cartAddedItems.coupon_code_description) {
            this.setState({
                cartItems: this.props.cartAddedItems.cart_items,
                price: this.props.cartAddedItems.order_total ? Number(this.props.cartAddedItems.order_total).toFixed(2) : 0,
                total_tax: this.props.cartAddedItems?.total_tax ? Number(this.props.cartAddedItems?.total_tax).toFixed(2) : 0,
                mrgPrice: this.props.cartAddedItems?.mrg_price_sub_total ? Number(this.props.cartAddedItems?.mrg_price_sub_total).toFixed(2) : 0,
                price_with_charges: this.props.cartAddedItems.total_amount ? Number(this.props.cartAddedItems.total_amount).toFixed(2) : 0,
                discount: this.props.cartAddedItems.coupon_discount ? Number(this.props.cartAddedItems.coupon_discount).toFixed(2) : 0,
                applied_code: this.props.cartAddedItems.coupon_code ? this.props.cartAddedItems.coupon_code : null,
                max_preparation_time: this.props.cartAddedItems.cart_items.map(function (a) {
                    return a.product.preparation_time
                }),
                code_description: this.props.cartAddedItems ? this.props.cartAddedItems.coupon_code_description : null,
                delivery_charge: this.props.cartAddedItems?.delivery_fee ? this.props.cartAddedItems?.delivery_fee : 0,
                delivery_tip: this.props.cartAddedItems?.delivery_tip ? this.props.cartAddedItems?.delivery_tip : 0,
                delivery_datetime: this.props.cartAddedItems?.delivery_datetime ? this.props.cartAddedItems?.delivery_datetime : "",
                quantity_product: null,
                quantity: null,
                quantity_amount: null,
                quantity_variable: null,
                quantity_customization: null,
                delivery_overall_weight: this.props.cartAddedItems.delivery_overall_weight ? this.props.cartAddedItems.delivery_overall_weight : 0,
            });
        }
        if (prevProps.cartAddedItems.delivery_datetime !== this.props.cartAddedItems.delivery_datetime) {
            this.setState({
                delivery_datetime: this.props.cartAddedItems?.delivery_datetime ? this.props.cartAddedItems?.delivery_datetime : "",
            });
        }
        if (prevProps.store !== this.props.store) {
            this.setState({selectedStore: this.props.store});
        }
        if (prevProps.preference !== this.props.preference) {
            this.setState({selectedPreference: this.props.preference});
        }
    }

    addToCart(val) {

        //cancel multiple request
        var time = 0;
        if (val.quantity === 0) {
            this.setState({remove_slide: val.item_id, remove_confirm: "slide-items confirm"});
            return false;
        } else {
            //cancel multiple request
            clearTimeout(timerId);
            time = 400;
        }

        //state update for multiple quantity request
        this.setState({
            quantity: val.quantity,
            quantity_amount: val.amount * val.quantity,
            quantity_product: val.id,
            quantity_variable: val.variable_id,
            quantity_customization: val.customize === 'No preference' ? '' : (val.customize || '')
        });

        //api calling with 500ms delay
        timerId = setTimeout(
            function () {

                const product = {
                    'menu_id': 3,
                    'restaurant': this.state.selectedStore,
                    "item_id": val.id,
                    "variable_id": val.variable_id,
                    "quantity": this.state.quantity,
                    'customization': val.customize === 'No preference' ? '' : (val.customize || ''),
                    'cart_item_id' : val?.cart_item_id || ''
                    // "removed": 0,
                    // "change": 0
                };

                this.setState({updating : true});
                api.addToCart(
                    product,
                    this.props.cart_id,
                    this.props.token,
                    this.state.selectedPreference
                ).then(success => {
                    if (success.data.status == "405") {
                        this.setState({showDeliveryOfflineConflictModal: true})
                    } else {
                        this.props.updateCartItems({
                            cart_id: this.props.cart_id,
                            token: this.props.token
                        });
                    }
                    setTimeout(() => {
                        this.setState({updating : false});
                    },1500);
                }).catch(error => {
                    this.setState({updating : false});
                    this.props.updateCartItems({
                        cart_id: this.props.cart_id,
                        token: this.props.token
                    });
                    this.props.enqueueSnackbar(error?.response?.data?.message, {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    });
                });
            }
                .bind(this),
            time
        );
    }

    save = (item_id, value) => {
        api.updateItemNote(item_id, {note: value}).then(success => {
            this.props.updateCartItems({
                cart_id: this.props.cart_id,
                token: this.props.token
            });
        });
    };

    cancel = () => {
    };

    selectHandler(event, customize = '',cart_item_id = '') {
        let item = JSON.parse(event.target.value);
        this.addToCart({
            variable_id: item.id,
            item_price: item.item_price,
            sell_price: item.sell_price,
            id: item.item_id,
            quantity: item.item_count ? item.item_count : 1,
            customize: customize === 'No preference' ? '' : customize,
            cart_item_id : cart_item_id || ''
        });
    }

    handleRemove(item_id) {
        api.removeFromCart(item_id).then(success => {
            this.setState({
                quantity: null,
                quantity_amount: null,
                quantity_product: null,
                quantity_variable: null,
                quantity_customization: null
            });
            this.props.updateCartItems({
                cart_id: this.props.cart_id,
                token: this.props.token
            });
        });
    };

    render() {

        const {isCheckout = false} = this.props;
        const {updating = false} = this.state;

        return (
            <React.Fragment>
                {this.props.readOnly({
                    'price': this.state.price,
                    'price_with_charges': this.state.price_with_charges,
                    'total_tax': this.state.total_tax,
                    'mrgPrice': this.state.mrgPrice,
                    'discount': this.state.discount,
                    'applied_code': this.state.applied_code,
                    'max_preparation_time': this.state.max_preparation_time.length > 0 ? Math.max(...this.state.max_preparation_time.map(o => o)) : 0,
                    'code_description': this.state.code_description,
                    'delivery_charge': this.state.delivery_charge,
                    'delivery_tip': this.state.delivery_tip,
                    'estimated_delivery_time': this.state.delivery_datetime ? convertTimStampToDateTime(this.state.delivery_datetime) : "",
                    'delivery_overall_weight' : this.state.delivery_overall_weight
                })}
                {this.props.checkoutOnly({
                    'price': this.state.price,
                    'price_with_charges': this.state.price_with_charges,
                    'total_tax': this.state.total_tax,
                    'mrgPrice': this.state.mrgPrice,
                    'discount': this.state.discount,
                    'applied_code': this.state.applied_code,
                    'code_description': this.state.code_description,
                    'delivery_charge': this.state.delivery_charge,
                    'delivery_tip': this.state.delivery_tip,
                    'estimated_delivery_time': this.state.delivery_datetime ? convertTimStampToDateTime(this.state.delivery_datetime) : "",
                    'delivery_overall_weight' : this.state.delivery_overall_weight
                })}
                {this.props.stock({
                    checkout: this.state.checkout,
                    items: this.props?.cartAddedItems?.cart_items?.length
                })}
                {this.props.orderLimit({
                    cart : this.props?.cartAddedItems
                })}
                {this.props.show === true && this.state.cartItems.length > 0 ?
                    <>
                        {this.props.preference === "2" && parseFloat(this.state.delivery_overall_weight) > parseFloat(process.env.RAZZLE_DELIVERY_RESTRICTED_WEIGHT) &&
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <div role="info" className="sc-1wz9i2x-0 cardErrorClr">
                                    <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M12 9v5M12 21.41H5.94c-3.47 0-4.92-2.48-3.24-5.51l3.12-5.62L8.76 5c1.78-3.21 4.7-3.21 6.48 0l2.94 5.29 3.12 5.62c1.68 3.03.22 5.51-3.24 5.51H12v-.01Z"
                                            stroke="#696969" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        <path d="M11.995 17h.009" stroke="#696969" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    <span className="text-danger" style={{fontSize : "12px"}}>
                                         Estimated delivery weight:{" "}{this.state.delivery_overall_weight} lbs (Exceeds the delivery order weight limit of 85 lbs.)
                                    </span>
                                </div>
                            </div>
                        }
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-m cart-itemlist">
                        <div className="bg-white main-section table-cart-main mb-0 mb-md-3">
                            <div className="table-responsive cart-table">
                                <table className="table mb-0">
                                    <tbody>
                                    {this.state.cartItems
                                        .filter((item) => {
                                            if ("Turkey" === 'Turkey') {
                                                return item
                                            }
                                        }).map((item) => {
                                            if (this.state.checkout === true) {
                                                parseInt(item.in_stock) === 0 ? this.setState({checkout: false}) : "";
                                            }
                                            return (
                                                <tr className={this.state.remove_slide === item.cart_item_id ? this.state.remove_confirm : "table-row slide-items"}
                                                    key={item.cart_item_id}>
                                                    {this.state.Change ? null : (
                                                        <td className="remove">
                                                            <div className="area">
                                                                <p>Are you sure you want to remove?</p>
                                                                <button
                                                                    className="btn btn-outline-dark w-auto px-3 py-1 mr-2"
                                                                    onClick={() => this.setState({remove_confirm: "table-row slide-items"})}>Cancel
                                                                </button>
                                                                <button className="btn btn-danger w-auto px-3 py-1"
                                                                        onClick={() => this.handleRemove(item.cart_item_id)}>Yes
                                                                </button>
                                                            </div>
                                                            <div className="close-btn">
                                                                <h6>
                                                                    <i className="fa fa-times"
                                                                       onClick={() => this.setState({remove_slide: item.cart_item_id}, () => {
                                                                           this.setState({remove_confirm: "table-row slide-items confirm"})
                                                                       })}>
                                                                    </i>
                                                                </h6>
                                                            </div>
                                                        </td>
                                                    )}
                                                    <td className="image">
                                                        <div className="contain">
                                                            <a href="javascript:void(0)" onClick={() =>
                                                                this.handleRedirection(`/shop/${item.product?.slug}`)
                                                            }>
                                                                <img
                                                                    src={
                                                                        item.product.images.length > 0 ? `https://cdn.farmersfreshmeat.com/211x211/${item.product.images[0].path}` : images.food
                                                                    }
                                                                    alt="food"
                                                                />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td className="name">
                                                        <div className="contain d-block">
                                                            <a href="javascript:void(0)" onClick={() =>
                                                                this.handleRedirection(`/shop/${item.product?.slug}`)
                                                            }>
                                                                <h6 className='fw-500'>
                                                                    {item.product.item_name ? item.product.item_name : null}
                                                                    {this.props.delivery && this.props.delivery.toString() === "available" ?
                                                                        <span className="font-weight-bold ml-2">X{item.item_count ? item.item_count : ""}</span> : ""}
                                                                </h6>
                                                            </a>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="row align-items-center">
                                                                <div className="col-auto pr-0">
                                                                    <CartDropDown
                                                                        variables={item.product.variables || []}
                                                                        preferences={item.product.customizations || []}
                                                                        handleVariables={this.selectHandler}
                                                                        btnLabel={`${(item.product && item.product.item_type === 2 && (item.variable?.unit?.name ?? "") === "pc") ? `Pieces: ${parseInt(item.variable?.weight)}` :
                                                                            (item.product && item.product.item_type === 2 && (item.variable?.unit?.name ?? "") !== "pc") ?
                                                                                /\d/.test(item.variable?.weight) ?
                                                                                    `Weight: ${item.variable?.weight + " " + (item.variable?.unit?.name ?? "")}`
                                                                                    :
                                                                                    `${item.variable?.weight}`
                                                                                :
                                                                                `${item.product && item.product.item_type === 1 && "Tray size: " + (CapitalizeFirstLetter(item.variable?.tray_size || ''))}`
                                                                        } ${(!!item?.customization && item?.customization !== 'No preference') ? `(${CapitalizeFirstLetter(item?.customization || '')})` : ''}`}
                                                                        cartValue={item}
                                                                        isAddedToCart={true}
                                                                        isCatering={item.product.item_type === 1}
                                                                        isDisabled={isCheckout || (item.product.variables?.length === 1 && !item.product.customizations?.length)}
                                                                        wrapperClass={'cart-item-dropdown'}
                                                                        isCartPage={true}
                                                                        productName={item.product?.item_name}
                                                                        itemId={item.product?.item_id+ item.variable_id + (!!item?.customization ? `(${item?.customization})` : '')}
                                                                    />
                                                                </div>
                                                                <div className="col-auto pl-md-0 pl-3 mt-1 mt-md-0 ex-badge">
                                                                    {
                                                                        parseInt(item.product.exclusive) === 1 ?
                                                                            <>
                                                                                <Badge pill variant="warning"
                                                                                       data-tip="Product available for specific dates only">Exclusive</Badge>
                                                                                <ReactTooltip/>
                                                                            </>
                                                                            : ""
                                                                    }
                                                                    {" "}
                                                                    {
                                                                        parseInt(item.in_stock) === 1 ?
                                                                            "" :
                                                                            <small className="text-danger">This item is
                                                                                Out-of-stock</small>
                                                                    }
                                                                    {" "}
                                                                    {
                                                                        parseInt(item.is_ebt_item) === 1 && parseInt(this.props?.ebt_payments) === 1 &&
                                                                            <Badge pill variant="primary" className="pt-1 px-2 d-block pb-1">EBT</Badge>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        {/*<div className="contain">*/}
                                                        {/*    <h6 className="text-sm">Preparation Time*/}
                                                        {/*        - {item.product.preparation_time !== null && parseInt(item.product.preparation_time) === parseInt(0) ? " Same day" : item.product.preparation_time + " days"}</h6>*/}
                                                        {/*</div>*/}
                                                        {this.props.delivery && this.props.delivery.toString() === "available" ? item.schedule_date ?
                                                            <div className="contain">
                                                                <p className="text-left">
                                                                    Delivered
                                                                    at: {item.schedule_date ? formatDate(item.schedule_date) : null}
                                                                </p>
                                                            </div>
                                                            : " " : " "
                                                        }
                                                    </td>
                                                    {this.state.Change ? (
                                                        <td className="quantity">
                                                            <div className="contain pro-qty">
                                                                <span>
                                                                    Qty{''}
                                                                    <span>
                                                                        {
                                                                            item.item_count
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <td className="quantity">
                                                            <div className={`num-block skin-5 d-none d-md-block ${updating ? 'actions-disabled' : ''}`}>

                                                                {this.props.delivery && this.props.delivery.toString() === "available" ?
                                                                    <span></span>
                                                                    : <>
                                                                        <div className="num-in">
                                                                        <span className="minus dis decrement"
                                                                              onClick={() => {
                                                                                  this.addToCart({
                                                                                      'id': item.item_id,
                                                                                      'quantity': ((this.state.quantity_product === item.item_id &&  this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity) ? this.state.quantity : item.item_count) - 1,
                                                                                      'variable_id': item.variable_id,
                                                                                      'item_id': item.cart_item_id,
                                                                                      'customize': item.customization || '',
                                                                                      'cart_item_id' :item.cart_item_id || '',
                                                                                  })
                                                                              }}
                                                                        >

                                                                        {((this.state.quantity_product === item.item_id && this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity) ? this.state.quantity : item.item_count) === 1 ?
                                                                            <i className="fa fa-trash"></i> :
                                                                            <i className="fa fa-minus"></i>}
                                                                        </span>

                                                                            <input type="text" className="in-num"
                                                                                   value={this.state.quantity_product === item.item_id &&  this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity ? this.state.quantity : item.item_count}
                                                                                   name="qty"/>

                                                                            <span className="plus increment"
                                                                                  onClick={() => this.addToCart({
                                                                                      'id': item.item_id,
                                                                                      'quantity': (this.state.quantity_product === item.item_id && this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity ? this.state.quantity : item.item_count) + 1,
                                                                                      'variable_id': item.variable_id,
                                                                                      'item_id': item.cart_item_id,
                                                                                      'customize': item.customization || '',
                                                                                      'cart_item_id' :item.cart_item_id || '',
                                                                                  })}
                                                                            >
                                                                        <i className="fa fa-plus"></i>
                                                                        </span>
                                                                        </div>
                                                                    </>
                                                                }

                                                                {item.item_count === 0
                                                                    ? this.handleRemove(
                                                                        item.cart_item_id
                                                                    )
                                                                    : null}

                                                            </div>
                                                        </td>
                                                    )}

                                                    <td className="price-details">
                                                        <div className="food-content contain">
                                                            {' '}
                                                            <p className="price">
                                                                <span>
                                                                    {/*{item.total_amount.toFixed(2)}*/}
                                                                    {formatNumber(this.state.quantity_product === item.item_id && this.state.quantity_customization === item.customization && this.state.quantity_variable === item.variable_id && this.state.quantity_amount ? this.state.quantity_amount.toFixed(2) : (item.amount * (item.item_count ? item.item_count : 1)).toFixed(2))}
                                                                </span>
                                                            </p>
                                                            <span className="each-price d-block mb-1">
                                                                ${(item.total_amount / item.item_count).toFixed(2)}
                                                            </span>
                                                        </div>
                                                        <div className={`num-block skin-5 d-block d-md-none ${updating ? 'actions-disabled' : ''}`}>

                                                            {this.props.delivery && this.props.delivery.toString() === "available" ?
                                                                <span></span>
                                                                : <>
                                                                    <div className="num-in">
                                                                        <span className="minus dis decrement"
                                                                              onClick={() => {
                                                                                  this.addToCart({
                                                                                      'id': item.item_id,
                                                                                      'quantity': (this.state.quantity_product === item.item_id && this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity ? this.state.quantity : item.item_count) - 1,
                                                                                      'variable_id': item.variable_id,
                                                                                      'item_id': item.cart_item_id,
                                                                                      'customize': item.customization || ''
                                                                                  })
                                                                              }}
                                                                        >
                                                                       {(this.state.quantity_product === item.item_id && this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity ? this.state.quantity : item.item_count) === 1 ?
                                                                           <i className="fa fa-trash"></i> :
                                                                           <i className="fa fa-minus"></i>}
                                                                        </span>

                                                                        <input type="text" className="in-num"
                                                                               value={this.state.quantity_product === item.item_id && this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity ? this.state.quantity : item.item_count}
                                                                               name="qty"/>

                                                                        <span className="plus increment"
                                                                              onClick={() => this.addToCart({
                                                                                  'id': item.item_id,
                                                                                  'quantity': (this.state.quantity_product === item.item_id && this.state.quantity_customization === (item.customization || '') && this.state.quantity_variable === item.variable_id && this.state.quantity ? this.state.quantity : item.item_count) + 1,
                                                                                  'variable_id': item.variable_id,
                                                                                  'item_id': item.cart_item_id,
                                                                                  'customize': item.customization || ''
                                                                              })}
                                                                        >
                                                                        <i className="fa fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            }

                                                            {item.item_count === 0
                                                                ? this.handleRemove(
                                                                    item.cart_item_id
                                                                )
                                                                : null}

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </>
                    : (
                        /*<!------------/items in your cart--------------->*/
                        this.props.show === true ?
                            <div className="col-md-12">
                                <div className="bg-white main-section emptyCart h-auto py-5 d-flex align-item-center justify-content-center">
                                    <div className="col-auto h-fit">
                                        <h5 className="w-auto mx-auto mb-1 text-center">No items added in you cart!</h5>
                                        <p className="w-auto mx-auto mb-3 text-center"></p>
                                        <div className="text-center"><a href="/"><button className="btn btn-m cart-button px-4 mx-auto w-auto">Order now</button></a></div>
                                    </div>
                                </div>
                            </div>
                            : ""
                    )
                }
                <DeliveryOfflineModal show={this.state.showDeliveryOfflineConflictModal}
                                      close={() => {
                                          this.setState({showDeliveryOfflineConflictModal: false})
                                      }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: 'UPDATE_CART_ITEMS', data: val}))
    }
};

export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CartItems)));
