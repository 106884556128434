import React from 'react';
import {NavLink} from "react-router-dom";
import FoodCard from "../../Common/foodCard";
import '../../../assets/css/slider_test.css'
import API from "../../../api";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import Button from "react-bootstrap/Button";

const api = new API();

class RecentViewed extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            all_products: [],
            scrollX: 0,
            scrolEnd: false
        },
            this.scrl = React.createRef();
    }

    componentDidMount() {
        api.getRecentViewedProducts({
            product_id: this.props.product_id,
            ids: this.props.cookies.get("recent_view_products")  !== undefined ? this.props.cookies.get("recent_view_products") : [],
            restaurant_id : this.props.selectedStore,
        }).then(products => {
            this.setState({all_products: products.data.response})
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.product_id !== this.props.product_id) {
            api.getRecentViewedProducts({
                product_id: this.props.product_id,
                ids: this.props.cookies.get("recent_view_products") !== undefined ? this.props.cookies.get("recent_view_products") : [],
                restaurant_id : this.props.selectedStore,
            }).then(products => {
                this.setState({all_products: products.data.response},()=>{
                    this.scrl.current.scrollLeft += ("-"+this.state.scrollX);
                })
            })
        }
    }

    //Slide click
    slide = (shift) => {
        this.scrl.current.scrollLeft += shift;
        this.setState({scrollX: scrollX + shift});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };


    scrollCheck = () => {
        this.setState({scrollX: this.scrl.current.scrollLeft});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };

    render() {
        return (
            <>
                {this.state.all_products.length > 0 ?
                    <React.Fragment>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 p-md-0">
                            <div className="title">
                                <h2>Recently Viewed</h2>
                                <NavLink to="/search/all" className="more-info">
                                    See more{' '}
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 complete-order recent-view px-0">
                            <div className="bg-white main-section complete-order mb-0 w-100 p-0 border-0">
                                <div className="most-product">
                                    <div className="col-12 p-0 cus-slider recent">
                                        {this.state.scrollX !== 0 ?
                                            <Button
                                                className="nav-btn prev"
                                                onClick={() => this.slide(-500)}
                                            >
                                                <i className="fa fa-angle-left"></i>
                                            </Button>
                                            : ""}

                                        <ul className="cus-slider mb-0" ref={this.scrl} onScroll={this.scrollCheck}>
                                            {this.state.all_products.map((val, index) => {
                                                if (index <= 12) {
                                                    return <FoodCard key={index} val={val} change="change"/>;
                                                }
                                            })}
                                        </ul>

                                        {!this.state.scrolEnd ?
                                            <Button
                                                className="nav-btn next"
                                                onClick={() => this.slide(+500)}
                                            >
                                                <i className="fa fa-angle-right"></i>
                                            </Button>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </React.Fragment>
                    : ""}
            </>
        );
    }
}

export default withCookies(RecentViewed)
