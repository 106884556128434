import React from 'react';
import FoodCard from "../../Common/foodCard";
import API from "../../../api";
const api = new API();

class FirstSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            all_products :[],
        }
    }

    componentDidMount() {
        const data = {  };
        api.getFilterProduct(data).then(products =>{
            this.setState({all_products: products.data.response.data})
        })
    }

    render() {

       const cart_products = this.props.products && this.props.products.length ?
            this.props.products.map((product) => {
              return product.item_id
            }) : [] ;

       var item_count = 0 ;

       return (
            <React.Fragment>
                <div className="row display-details">
                    {!!this.state.all_products?.length &&
                    this.state.all_products.map((val, index) => {

                        if (val.item_type === 2 && val.variables.length > 0 && parseInt(this.props.preference) == 2 && val.is_deliverable === 1 && !cart_products.includes(val.item_id) && item_count < 15) {
                            item_count = item_count + 1 ;
                            return <FoodCard key={index} val={val}/>
                        }

                        if (val.item_type === 2 && val.variables.length > 0 && parseInt(this.props.preference) == 1 && !cart_products.includes(val.item_id) && item_count < 15) {
                            item_count = item_count + 1 ;
                            return <FoodCard key={index} val={val}/>
                        }

                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default FirstSection
