/* DEFAULT WAY NOT WORKING ON IPHONE */

import React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import FoodCard from "../../Common/foodCard";
import ScrollContainer from 'react-indiana-drag-scroll';

class Related extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            all_products: [],
            categories: [],
            scrollX: 0,
            scrolEnd: false
        };
        this.scrl = React.createRef();
    }

    componentDidMount() {
        if (this.state.all_products.length !== this.props.products.length) {
            this.setState({all_products: this.props.products , scrollX : 0});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.products !== this.props.products) {
            this.setState({all_products: this.props.products,scrollX : 0},()=>{
                this.scrl.current.scrollLeft += ("-"+this.state.scrollX);
            });
        }
    }

    //Slide click
    slide = (shift) => {
        this.scrl.current.scrollLeft += shift;
        this.setState({scrollX: scrollX + shift});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };


    scrollCheck = () => {
        this.setState({scrollX: this.scrl.current.scrollLeft});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };

    render() {
        return (

            <>
                <React.Fragment>

                    {this.props.location.pathname.startsWith("/shop") &&
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 p-md-0">
                        <div className="title">
                            <h2>Related Products</h2>
                            <NavLink to="/search/all" className="more-info">
                                See more{' '}
                            </NavLink>
                        </div>
                    </div>
                    }

                    {this.state.all_products.length > 0 ? (
                        <div className="col-12 p-0">
                            <div className="col-12 p-0 cus-slider">
                                <ScrollContainer
                                    className="scroll-container"
                                    hideScrollbars={false}
                                >
                                    {this.state.all_products.length > 4 && this.state.scrollX !== 0 ?
                                        <button
                                            className="nav-btn prev"
                                            onClick={() => this.slide(-500)}
                                        >
                                            <i className="fa fa-angle-left"></i>
                                        </button>
                                        : ""}


                                    <ul className="cus-slider" ref={this.scrl} onScroll={this.scrollCheck}>
                                        {this.state.all_products.map((val, index) => {
                                            if(index <= 12 && val.variables.length > 0) {
                                                return <FoodCard key={index} val={val} change="change"/>;
                                            }
                                        })}
                                    </ul>

                                    {this.state.all_products.length > 4 && !this.state.scrolEnd ?
                                        <button
                                            className="nav-btn next"
                                            onClick={() => this.slide(+500)}
                                        >
                                            <i className="fa fa-angle-right"></i>
                                        </button>
                                        : ""}

                                </ScrollContainer>
                            </div>
                        </div>
                    ) : null}

                </React.Fragment>
            </>
        );
    }
}

export default withRouter(Related);
