import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {images} from "../../constant";
import CommonStaticSidebar from "./commonStaticSidebar";
import FoodCard from "../Common/foodCard";
import API from "../../api";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import "../../assets/css/notification.css";
import "../../assets/css/input.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

const api = new API();

class Promotion extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            default_store: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
            all_products: [],
            scrollX: 0,
            scrolEnd: false,
            onSale: false,
            mobile_number: '',
            restaurant_id: '',
            errorAlert : null,
            successAlert : ""
        };
        this.alertBox = React.createRef();
        this.scrl = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        api.getProducts({
            page: 1,
            restaurant_id: this.state.default_store,
            categories_slug: "weekly-sale"
        }).then((products) => {
            this.setState({all_products: products.data.response.data, onSale: true}, () => {
                if (this.state.all_products.length < 1) {
                    api.getProducts({page: 1, restaurant_id: this.state.default_store}).then((products) => {
                        this.setState({all_products: products.data.response.data, onSale: false});
                    });
                }
            });
        });
    }

    handleSubmit() {

        this.setState({errorAlert : "", successAlert : ""});

        let errorRef = null;

        if (this.state.mobile_number === "" || this.state.mobile_number?.length < 10) {
            errorRef = "Enter valid mobile number";
        }
        if (this.state.restaurant_id === "") {
            errorRef = "Please select restaurant";
        }
        if (errorRef) {
            this.setState({errorAlert: errorRef}, () => {
                this.alertBox.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            });
            return false;
        }

        api.promotionalSubscribe({
            mobile_number: this.state.mobile_number,
            restaurant_id: this.state.restaurant_id
        }).then((success) => {
            if (success.data.status == "400") {
                this.setState({errorAlert: success.data.message}, () => {
                    this.alertBox.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                });
                return false;
            }
            this.setState({
                mobile_number: '',
                restaurant_id: '',
                successAlert: true
            });
        }) .catch((error) => {
            this.setState({
                errorAlert: error.response.data.message,
            });
        });
    }

    //Slide click
    slide = (shift) => {
        this.scrl.current.scrollLeft += shift;
        this.setState({scrollX: scrollX + shift});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };


    scrollCheck = () => {
        this.setState({scrollX: this.scrl.current.scrollLeft});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };

    handleRedirection() {
        localStorage.setItem('filter', JSON.stringify({
                range: [0, 1000],
                categories_slug: null,
                categories_name: null,
                filter: {
                    is_prime: false,
                    is_bulk: false,
                    made_locally: false,
                    is_on_sell: false
                },
            }
        ));

        this.props.history.push({
            pathname: "/"
        });
    }

    render() {
        const marketingProducts = this.state.all_products.length > 0 ? this.state.all_products : [];
        const iframe = '<iframe src="https://widget.smsinfo.io/v2/c72afe92b0f3a4a22e0716b7edffa88f" width="401" height="417" frameborder="0" /></iframe>';
        const iframe2 = '<iframe src="https://widget.smsinfo.io/v2/bfae2e398f41601265824c0b22917465" width="401" height="417" frameborder="0" /></iframe>';
        return (
            <>
                <React.Fragment>
                    {!this.props?.home &&
                    <div className="about-banner">
                        <div className="image-wrap">
                            <div className="img-content">
                                <img
                                    src={images.about}
                                    alt="Not Found"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="banner-content">
                            <h1>Promotions</h1>
                        </div>
                    </div>
                    }
                    <div className="container-fluid  mb-fix promotion">
                        <div className="col-12 px-0 px-md-3 pt-3">
                            <div className="row">
                                {!this.props?.home &&
                                <CommonStaticSidebar/>
                                }
                                <div className={this.props?.home ? "col-md-12 sec-main-promo" : "col-md-9"}>
                                    {!this.props?.slider && this.state.all_products.length > 0 &&
                                        <>
                                            <div className="col-12 mb-3 pl-md-0">
                                                <div className="title mb-3 px-0 d-flex align-items-center justify-content-between">
                                                    <h2 className="mb-0 fw-600">{this.state.onSale === true ? "Items on sale" : "Checkout Our Bestsellers"}</h2>
                                                </div>
                                                <div className="col-12 title px-0 mb-0">
                                                    <div className="col-12 x-0">
                                                        <div className="row">
                                                            {marketingProducts.length > 0 &&
                                                                <div
                                                                    className="bg-white main-section complete-order w-100 mb-0 p-0 border-0">
                                                                    <div className="most-product">
                                                                        <div className="col-12 cus-slider">

                                                                            {this.state.scrollX !== 0 &&
                                                                                <button className="nav-btn prev"
                                                                                        onClick={() => this.slide(-500)}>
                                                                                    <i className="fa fa-angle-left"></i>
                                                                                </button>
                                                                            }

                                                                            <ul className="cus-slider recent mb-0"
                                                                                ref={this.scrl}
                                                                                onScroll={this.scrollCheck}>
                                                                                {marketingProducts.map((val, index) => {
                                                                                    if (index <= 12 && val.variables.length > 0) {
                                                                                        return <FoodCard
                                                                                            key={index}
                                                                                            val={val}
                                                                                            change="change"/>;
                                                                                    }
                                                                                })}
                                                                            </ul>

                                                                            {!this.state.scrolEnd &&
                                                                                <button className="nav-btn next"
                                                                                        onClick={() => this.slide(+500)}>
                                                                                    <i className="fa fa-angle-right"></i>
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-12 px-0 line-break pt-2 pb-3 pt-md-1 pb-md-3"><hr className="my-md-4 my-4"/></div>
                                    <div className="col-12 main-section bg-primary-25 h-auto px-md-4 pt-md-4 px-3 pt-3 text-left mb-4 pb-0 position-relative">
                                        <div className="row justify-content-between align-items-end">
                                            <div className="col-md-9 pb-md-4 pb-5">
                                                <div className="col-12 px-0 mb-1">
                                                    <h3 className="h3-big fw-600 mb-2 pb-2">Get Farmer's VIP Text Alerts <span className="fw-500">for Deals, Free Giveaways & Promotions!</span></h3>
                                                    <div className="col-12 px-md-3 px-2">
                                                        <h6 className="mb-2 fw-400">Pick your closest location:</h6>
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="col-auto custom-check login-check px-0">
                                                                    <label
                                                                        className="control control--checkbox mb-0 fs-10">
                                                                        Farmer's at Mesa,
                                                                        <small
                                                                            className="pl-1 d-inline-block t-70 fs-13 ">9541
                                                                            Mesa Dr. Houston, TX 77078</small>
                                                                        <input
                                                                            value={this.state.restaurant_id}
                                                                            type="radio"
                                                                            name="mobile_subscription"
                                                                            onChange={(e) => this.setState({restaurant_id: 2}, () => {
                                                                                this.setState({errorAlert: null})
                                                                            })}
                                                                        />
                                                                        <div className="control__indicator"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 pt-2">
                                                                <div className="col-auto custom-check login-check px-0">
                                                                    <label
                                                                        className="control control--checkbox mb-0 fs-10">
                                                                        Farmer's at Cullen,
                                                                        <small
                                                                            className="pl-1 d-inline-block t-70 fs-13 ">8630
                                                                            Cullen Blvd Houston, TX 77051</small>
                                                                        <input
                                                                            value={this.state.restaurant_id}
                                                                            type="radio"
                                                                            name="mobile_subscription"
                                                                            onChange={(e) => this.setState({restaurant_id: 1}, () => {
                                                                                this.setState({errorAlert: null})
                                                                            })}
                                                                        />
                                                                        <div className="control__indicator"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-md-3 pt-2 px-2">
                                                    <div className="col-12 mt-2 pt-2 mb-2">
                                                        <div className="row">
                                                            <div className="col-md-6 pl-md-0 pr-md-3 px-0 mb-0">
                                                                <div className="col-12 form-group px-0 mb-md-0">
                                                                    <PhoneInput
                                                                        placeholder="Enter phone number"
                                                                        country={'us'}
                                                                        disableCountryGuess={true}
                                                                        disableCountryCode={true}
                                                                        disableDropdown={true}
                                                                        value={this.state.mobile_number}
                                                                        onChange={phone =>
                                                                            this.setState({
                                                                                mobile_number: phone
                                                                            },()=>{
                                                                                this.setState({errorAlert : null})
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-auto px-0 pt-0">
                                                                <button className="btn btn-proceed px-3 px-md-4 py-2 h-100 w-100" onClick={this.handleSubmit}>Subscribe & save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 px-0 pt-1">
                                                        <p className="mb-0 fs-13 text-black t-70 font-weight-normal">
                                                            By subscribing, you agree to our <a
                                                            href="https://terms.smsinfo.io/tc.php?id=930587"
                                                            className="link" target="_blank">Terms & Policies</a>.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/*<div className="col-12 pt-3 px-md-2">*/}
                                                    {this.state.errorAlert ? (
                                                        <small
                                                            ref={this.alertBox}
                                                            role="alert"
                                                            className="alert-message fs-12 sc-1wz9i2x-0 fooVeK mt-1"
                                                        >
                                                            <small className="text-message fs-12 text-danger">{this.state.errorAlert}</small>
                                                            {/*<small className="text-message text-danger">Please choose a restaurant you wish to subscribe to.</small>*/}
                                                        </small>
                                                    ) : null}


                                                    {this.state.successAlert ? (
                                                        <small
                                                            ref={this.alertBox}
                                                            role="alert"
                                                            className="alert-message fs-12 sc-1wz9i2x-0 fooVeK fooVeKSuccess d-flex align-items-center align-items-start "
                                                        >
                                                            <small className="text-message fs-12">Thanks for subscribing.&nbsp;&nbsp;Expect a confirmation message shortly for double verification.</small>
                                                        </small>
                                                    ) : null}
                                                {/*</div>*/}
                                            </div>
                                            <div className="col-md-3 pl-md-4 px-5 pr-md-3">
                                                <img className="img-fluid px-4 px-md-0" src={images.mailBox}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </>
        )
    }
}

export default withCookies(withRouter(Promotion));
