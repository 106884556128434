import React from 'react';
import {withRouter, Link} from 'react-router-dom';

class NotFound extends React.Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section className="page_404 ">
        <div className="col-auto text-center content">
          <div className="four_zero_four_bg">
            <h1 className="text-center mb-1">Oops!</h1>
          </div>
          <div className="contant_box_404">
            <h3 className="">
              Something went wrong
            </h3>
            <p className="mb-3">The page you are looking for is not available</p>

            <Link to={'/'}><button className={'btn btn-m cart-button w-auto px-3 menu-toggle'}>Back to home</button></Link>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(NotFound);
