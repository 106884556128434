// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACi5HCb47ryrGp7W9tSwvnWivH0R1WQX0",
  authDomain: "ffk-v2.firebaseapp.com",
  databaseURL: "https://ffk-v2-default-rtdb.firebaseio.com",
  projectId: "ffk-v2",
  storageBucket: "ffk-v2.appspot.com",
  messagingSenderId: "55736714476",
  appId: "1:55736714476:web:4d44d9206b81215f6c11c9",
  measurementId: "G-SGQG3D45EP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export default db;