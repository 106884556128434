import React from "react";
import {NavLink, withRouter} from 'react-router-dom';
import 'rc-slider/assets/index.css';
import {images} from '../../constant';
import {Range} from "rc-slider";
import API from "../../api";
let timerId ;

const api = new API();

class CategoryPageSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            restaurants: [],
            category_slug: null,
            start_price : 0,
            end_price : 0,
            value: [0,0],
            is_prime: false,
            is_bulk: false,
            categories_slug: null,
            categories_name: null,
            restaurant_id: null,
            keyword: this.props.match.params.search_key,
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateRange = this.updateRange.bind(this);
        this.refreshCategory = this.refreshCategory.bind(this);
        this.handleRestaurant = this.handleRestaurant.bind(this);
    }

    refreshCategory(slug,name) {
        const {location} = this.props
        const path = (location.pathname === "/" ? "all" : this.props.match.params.search_key);

        api.getCategories({category : slug ,keyword : path }).then(categories => {
            this.setState({
                categories: categories.data.response.categories,
                value: [ categories.data.response.start_price,categories.data.response.end_price ],
                start_price : categories.data.response.start_price,
                end_price : categories.data.response.end_price,
            })
        });

        this.setState({categories_slug: slug ,categories_name : name }, () => {
            this.props.history.push({
                pathname: '/search/' + path,
                state: {categories_slug: slug,categories_name: name}
            });
        });
    }

    handleRestaurant(id) {
        this.setState({restaurant_id: id})
    }

    componentDidMount() {
        api.getCategories(this.props.match.params.search_key ? {keyword: this.props.match.params.search_key,category : this.state.categories_slug} : "",
        ).then(categories => {
            this.setState({
                categories: categories.data.response.categories,
                value: [ categories.data.response.start_price,categories.data.response.end_price ],
                start_price : categories.data.response.start_price,
                end_price : categories.data.response.end_price
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.dataFromParent !== this.props.dataFromParent) {
            api.getCategories(this.props.dataFromParent ? {keyword: this.props.dataFromParent ,category : this.state.categories_slug} : "",
            ).then(categories => {
                this.setState({
                    categories: categories.data.response.categories,
                    value: [ categories.data.response.start_price,categories.data.response.end_price ],
                    start_price : categories.data.response.start_price,
                    end_price : categories.data.response.end_price
                })
            })
        }
    }

    handleChange(event) {
        const state = event.target.name;
        if (state === "is_prime") {
            this.setState({is_prime: event.target.type === "checkbox" ? event.target.checked : event.target.value});
        }
        if (state === "is_bulk") {
            this.setState({is_bulk: event.target.type === "checkbox" ? event.target.checked : event.target.value});
        }
    }

    updateRange(value) {  ///RANGE FILTER FUNCTION
        this.setState({value: value});
    };

    render() {

        const {location} = this.props
        const exclude = ['/'];

        //cancel multiple request
        clearTimeout(timerId);

        //api calling with 500ms delay
        timerId = setTimeout(
            function() {

                this.props.handler ? this.props.handler({
                    'range': this.state.value,
                    'is_prime': this.state.is_prime,
                    'is_bulk': this.state.is_bulk,
                    'categories_slug': this.state.categories_slug,
                    'categories_name': this.state.categories_name,
                    'restaurant_id': this.state.restaurant_id
                }) : null
            }
                .bind(this),
            500
        );

        return (

            <>
                <React.Fragment>
                    <div className="col-12 col-md-2 col-lg-2 col-xl-3 column1">
                        <div className="sidebar d-md-none d-lg-block d-xl-block bg-white">
                            <h2>Shop from</h2>
                            <ul>
                                <li>
                                    <a href="/" className={ this.state.categories_slug === "" ? "active" : ""  } onClick={() => {
                                        this.refreshCategory("","")
                                    }}>
                                        All
                                    </a>
                                </li>

                                {
                                    this.props.prods && this.props.prods.length ?  this.props.prods.map(category => (
                                        <li key={category.id}>
                                            <a href={'/product-category/' + category.slug}>
                                                {category.name}
                                            </a>
                                        </li>
                                    )) : "" }
                            </ul>
                            {/*{exclude.find((item) => location.pathname === item) ? null :*/}
                            <>
                                <div className="col-md-12 filter">
                                    <h2>Price Range</h2>
                                    <div className="range-slider">
                                        <p>${this.state.value[0]}</p>
                                        <p>${this.state.value[1]}</p>
                                    </div>
                                    <Range
                                        min={this.state.start_price}
                                        max={this.state.end_price}
                                        onChange={this.updateRange}
                                        defaultValue={[this.state.start_price, this.state.end_price]}
                                        value={this.state.value}
                                    />
                                </div>
                                <div className="col-md-12 filter">
                                    <h2> Ways to shop </h2>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <img
                                                src={
                                                    images.imgP
                                                }
                                                alt="Not Found"
                                            />
                                            <b> Prime Items</b>
                                            <input type="checkbox" name="is_prime" id="3"
                                                   value={this.state.is_prime} onClick={this.handleChange}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                    <h6>
                                        <label className="control control--checkbox">
                                            <img
                                                src={
                                                    images.imgB
                                                }
                                                alt="Not Found"
                                            />
                                            <b> Bulk Items</b>
                                            <input type="checkbox" name="is_bulk" id="4"
                                                   value={this.state.is_bulk} onClick={this.handleChange}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </h6>
                                </div>
                            </>
                            {/*}*/}
                        </div>
                    </div>
                </React.Fragment>
            </>
        )
    }
}


export default withRouter(CategoryPageSidebar);
