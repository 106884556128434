
import {USER_LIST} from "../constants";
import API from "../api";
const api = new API();

export const updateList = (data) => {
    return (dispatch) => {
        if (data.data.list_unique_id !== null && data.data.list_unique_id !== "blank"  && data.data.list_unique_id !== "default") {
            api.getUserList(data.data.token, data.data.list_unique_id).then(res => {
                return dispatch({
                    type: USER_LIST,
                    data: res.data.response
                });
            }).catch(error => {
                return dispatch({
                    type: USER_LIST,
                    data: {}
                });
            });
        }
    }
};
